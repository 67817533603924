<template>
    <section id="ProjTiku">
        

        <div class="container">
        
        <!-- PAGE TITLE -->
            <page-title 
                title="Tiku - Love App" 
                subtitle="Sulla base dei dati in materia e dell'analisi degli stili di vita, dei gusti e delle preferenze dei due partner, si creano coppie di perfetti sconosciuti. <br>Un vero esperimento sociale e psicologico. Le persone possono registrarsi e inserire i propri dati, scegliere la modalità e il mood della giornata ed entrare in una dimensione reale alla ricerca dell'amore, di una chiacchierata o di un fugace incontro." 
            />
        <!-- --------------------- -->

        <!-- BRANDING -->
            <text-and-img title='Branding' imgType="contain" img='proj-tiku/logo.png'>
                <p>Il nome Tiku è composto dall'onomatopea "tik" che ricorda il suono di due magneti che si toccano e la "u", abbreviazione di ”you”. Nel logo la u diventa una calamita sopra la quale è stato inserito un cuoricino rosso, simbolo dell'amore e delle linee indicano connessione wireless.</p>
                <p>Il rosso attira l'attenzione, risveglia immediatamente i sensi. È sinonimo di forte passione, personalità importante e fiducia in se stessi, il colore blu è sinonimo di calma, tranquillità ed equilibrio. Ha la capacità di generare un senso di rilassamento che dona equilibrio nella sfera emotiva.</p>
                <p>Il payoff "perfetto, ora, per sempre" vuole esprimere appieno l'obiettivo del marchio, darti ciò di cui hai bisogno ora e trovare la tua anima gemella.</p>
            </text-and-img>
        <!-- --------------------- -->
        <!-- PRODUCT -->
            <text-and-img title='Product' imgType="contain" img='proj-tiku/products.png'>
                <p>Il braccialetto Tiku è disponibile in due versioni.
                <br>Il primo in acciaio meno economico ma più elegante.
                <br>La seconda in vari colori, di gomma; più leggera, più economica e alla portata di tutti.
                <br>Al suo interno è presente un magnete che si attiva quando l'app è collegata al braccialetto trova un'affinità con una persona nelle circostanze, vibrando per avvisare (come una notifica).
                <br>Una volta acquistato il braccialetto, ti basterà scaricare la relativa app, registrarti inserendo i tuoi dati e il gioco è fatto.</p>
            </text-and-img>
        <!-- --------------------- -->

        <!-- ADVERTISING -->
            <text-and-img title='Advertising' imgType="cover" img='proj-tiku/advs.png'>
                <p>
                    Il titolo è "gli opposti si attraggono", il detto ”opposti si attraggono”, oltre a simboleggiare l'amore possibile tra chiunque si riferisce anche al fatto che il braccialetto si collega con una calamita al polo opposto al proprio.
                    <br>Per le immagini abbiamo deciso di scegliere coppie di opposti per simboleggiare l'amore.
                    <br>Tre immagini sono state scelte per comporre un multi-soggetto.
                </p>

            </text-and-img>
        <!-- --------------------- -->

        <!-- ADV SHOWREEL -->
            <Showreel
                title="Advertisings"
                :imgs="[
                    {
                        find: 'proj-tiku/adv1.jpeg',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-tiku/adv2.jpeg',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-tiku/adv3.jpeg',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                ]"
            />
        <!-- --------------------- -->

        <!-- EVENT GALLERY -->
            <gallery
                title="Our Event"
                :imgs="[
                    {
                        find: 'proj-tiku/event1.png',
                        alt: 'image1', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-tiku/event2.png',
                        alt: 'image2', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-tiku/event3.png',
                        alt: 'image3', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-tiku/event4.png',
                        alt: 'image4', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-tiku/event5.png',
                        alt: 'image4', // <-- image alt text
                        text: ''
                    },
                ]"
            />
        <!-- --------------------- -->

        <!-- VIDEO -->
            <VideoCard
                title="Video Teaser"
                :video="{
                    find: 'proj-tiku/TEASER.mp4', //only mp4!!
                }"
            />
        <!-- --------------------- -->

        </div>
    </section>
</template>

<script>
import PageTitle from '../../components/small/PageTitle.vue'
import Gallery from '../../components/works-project/Gallery.vue'
import Showreel from '../../components/works-project/Showreel.vue'
import TextAndImg from '../../components/works-project/TextAndImg.vue'
// import ReadThe from '../../components/works-project/ReadThe.vue'
import VideoCard from '../../components/works-project/VideoCard.vue'

export default {
    name: 'ProjTiku',
    components: {
        PageTitle,
        Showreel,
        TextAndImg,
        Gallery,
        // ReadThe,
        VideoCard,
    },
    metaInfo: {
        title: 'Tiku - Love App',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: 'Sulla base dei dati in materia e dell\'analisi degli stili di vita, dei gusti e delle preferenze dei due partner, si creano coppie di perfetti sconosciuti. Un vero esperimento sociale e psicologico. Le persone possono registrarsi e inserire i propri dati, scegliere la modalità e il mood della giornata ed entrare in una dimensione reale alla ricerca dell\'amore, di una chiacchierata o di un fugace incontro.',
        }],
    },
    data(){
        return{
            //
        }
    },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.adv-img-box{
    img{
        // height: 500px;
        max-width: 100%;
        border: 5px solid black; 
        margin-top:15px;
        position: relative;
    }
}

</style>