<template>
    <section class="VideoCard">
        <div class="top-bar">
            <h3>{{title != null && title != '' ? title : 'Watch this Video'}}</h3>
        </div>

        <div class="content">
            <div class="content_container">
                <div class="video">
                        <video controls loop>
                            <source :src="require(`@/assets/imgs/${video.find}`)" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'VideoCard',
    props:{
        title: String,
        video: Object
    },
    data(){
        return{
            //
        }
    },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.VideoCard{
    margin-top: $work-comp-mt;

    background-color: $c-window-bg;
    border: $main-borders;
    box-shadow: $shadow;

    .top-bar{
        padding: 15px 30px;
        border-bottom: $main-borders;
        h3{
            @include f-secondary-top-title;
        }
    }

    .content{
        position: relative;
        
        .content_container{
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            padding: 15px 0;
            .video{
                $margin-vert: 15px;
                $margin-oriz: 30px;
                margin: $margin-vert $margin-oriz;
                box-shadow: $shadow;
                border: $main-borders;
                max-width: calc(100% - ($margin-oriz * 2));

                video{
                    max-width: 100%;
                    margin-bottom: -5px;
                }
            }
        }
    }
}

</style>