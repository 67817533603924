<template>
    <section class="Gallery">
        <div class="top-bar">
            <h3>{{title != null && title != '' ? title : 'Image Gallery'}}</h3>
        </div>

        <div class="content">
            <div class="content_container">
                <div class="img" 
                    v-for="image in imgs"
                    :key="image.id">
                        <img 
                            :src="require(`@/assets/imgs/${image.find}`)"
                            :alt="image.alt"
                        >
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Gallery',
    props:{
        title: String,
        imgs: Array
    },
    data(){
        return{
            //
        }
    },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.Gallery{
    margin-top: $work-comp-mt;

    background-color: $c-window-bg;
    border: $main-borders;
    box-shadow: $shadow;

    .top-bar{
        padding: 15px 30px;
        border-bottom: $main-borders;
        h3{
            @include f-secondary-top-title;
        }
    }

    .content{
        position: relative;
        
        .content_container{
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            padding: 15px 0;
            .img{
                $margin-vert: 15px;
                $margin-oriz: 30px;

                @include img-fit-cover;
                margin: $margin-vert $margin-oriz;
                width: calc(33.33% - ($margin-oriz * 2));
                height: 250px;
                border: $main-borders;
                box-shadow: $shadow;

                @media screen and (max-width: 1000px){
                    width: calc(50% - (($margin-oriz) * 2));
                }
                @media screen and (max-width: 660px){
                    margin: $margin-vert calc($margin-oriz - 15px);
                    width: calc(100% - (($margin-oriz - 15px) * 2));
                }
            }
        }
    }
}

</style>